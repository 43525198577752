'use client';

import { useState } from 'react';
import { AngledArrowDownIcon, AngledArrowUpIcon } from '../../ui/icons/icons';

const ShowMoreBtn = (props: any) => {
  const [showState, setShowState] = useState(false);
  const showMoreHandler = () => {
    setShowState((latestShowState) => !latestShowState);
    props.onShowState(showState);
  };

  return (
    <button
      onClick={showMoreHandler}
      className="w-full flex mt-9 font-semibold gap-2 text-purple500 text-sm justify-center hover:cursor-pointer"
    >
      {showState ? props.showLessText : props.showMoreText}
      {showState ? (
        <AngledArrowUpIcon className="text-xl text-purple500" />
      ) : (
        <AngledArrowDownIcon className="text-xl text-purple500" />
      )}
    </button>
  );
};

export default ShowMoreBtn;
