import classNames from 'classnames';

type Props = {
  children: React.ReactNode;
  className?: string;
  noShadow?: boolean;
};
export const RoundedContainer: React.FC<Props> = ({
  children,
  className,
  noShadow = false,
}) => {
  return (
    <div
      className={classNames(
        { 'shadow-faq': !noShadow },
        'rounded-xl',
        className
      )}
    >
      {children}
    </div>
  );
};
