'use client';
import NativeCard from '@/src/features/common/native-card';
import { ResponseNativeCard } from '@/src/interfaces/data-contracts';
import { DeviceContext } from '@/src/store/contexts/device-context';
import classNames from 'classnames';
import React, { useContext } from 'react';

type Props = {
  item: ResponseNativeCard;
};
// todo there is a strange behavior with the isMobileViewServerState() function, when enabled and 'use client' is removed,
// next.js will throw an error saying that it is conflicting with the route src/app/ticari-kredi/sorgulama/page.tsx
// which has no relation to this component, so client side isMobile check is used instead.
// Check the issue again after the next.js update. 29/03/2023
export const NativeCardItemCreditCard: React.FC<Props> = ({ item }) => {
  const { isMobile } = useContext(DeviceContext);
  return (
    <div className={classNames('grid w-full gap-x-2 md:gap-x-8 items-center mt-2 mb-2')}>
      <NativeCard item={item} isMobile={isMobile} />
    </div>
  );
};
