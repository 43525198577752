import { RelLink } from '@/src/features/ui/rel-link';
import classNames from 'classnames';
import Image from 'next/image';
import React from 'react';
import style from './index.module.scss';
import { BadgeType, ResponseMenuItem } from '@/src/interfaces/data-contracts';
import printBadgeType from '@/src/utils/helpers/badge-type';

type Props = {
  item: ResponseMenuItem;
  index: number;
  onClick: any;
};
export const Card: React.FC<Props> = (props) => {
  const item = props.item;
  const index = props.index;
  return (
    <button onClick={props.onClick} key={index} className={classNames(style.linkItem, 'flex w-[165px] md:w-[160px]')}>
      {item.url && (
        <RelLink
          title={item.name ?? ''}
          data-testid={item.id}
          href={item.url}
          className={classNames(
            { [style.disabled]: item.badgeType === (30 as unknown as BadgeType) },
            style.innerLink,
            'relative flex h-[68px] w-[165px] flex-row items-center rounded-2xl border border-transparent bg-white p-4 text-sm font-semibold leading-7 hover:border-purple200 hover:bg-purple100 md:h-full md:w-full md:flex-col md:justify-between md:gap-4 md:px-4 md:py-8 md:text-xl'
          )}
        >
          <div
            className={classNames('relative h-[28px] w-[28px] shrink-0 md:h-[32px] md:w-[32px]', {
              [(style.disabled, 'opacity-50')]: item.badgeType === (30 as unknown as BadgeType),
            })}
          >
            {item.activeImagePath && <Image src={item.activeImagePath} fill alt={item.name ?? '-'} />}
          </div>
          <span
            className={classNames('ml-2 text-sm leading-4 md:ml-0 md:text-center md:leading-5', style.text, {
              [(style.disabled, 'opacity-50')]: item.badgeType === (30 as unknown as BadgeType),
            })}
          >
            {item.name}
            {/*{item.available && <ArrowMainPromoIcon width={18} height={28} />}*/}
          </span>
          {item.badgeType && (
            <span
              className={classNames(
                'absolute -top-[0px] right-[0px] self-center rounded-bl-xl rounded-tr-xl px-[6px] py-1 text-xs font-semibold leading-4 text-intro md:text-sm',
                { 'bg-introLight text-intro': item.badgeType === (10 as unknown as BadgeType) },
                { 'bg-purple50 text-purple600': item.badgeType === (20 as unknown as BadgeType) },
                { 'bg-orange50 text-orange500': item.badgeType === (30 as unknown as BadgeType) },
                style.banner
              )}
            >
              {printBadgeType[item.badgeType]}
            </span>
          )}
          {!item && (
            <span
              className={classNames(
                'absolute right-[0px] top-[0px] self-center rounded-bl-xl rounded-tr-xl bg-introLight px-2 font-semibold text-intro md:text-xs',
                style.banner
              )}
            >
              Çok Yakında
            </span>
          )}
        </RelLink>
      )}
    </button>
  );
};
