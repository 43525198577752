import { validateUrl } from '@/src/utils/helpers/validate-url';
import Image from 'next/image';

const ImageWithFallback = (props: { [x: string]: any; src: any; alt: any }) => {
  const { src, alt, ...rest } = props;
  const regxCheck = /\.(gif|jpe?g|tiff?|png|webp|svg|bmp)$/i.test(src);

  const broken = 'https://via.placeholder.com/803x425.png?text=Görsel%20Yükleniyor...';
  const ckImg = regxCheck && validateUrl(src) ? src : broken;
  return (
    <Image
      alt={alt}
      quality={80}
      src={ckImg}
      key={ckImg}
      sizes={`${ckImg} 1200w,${ckImg}?w=200 200w${ckImg}?w=400 400w,${ckImg}?w=800 800w,${ckImg}?w=1024 1024w`}
      decoding="async"
      loading="lazy"
      {...rest}
    />
  );
};

export default ImageWithFallback;
