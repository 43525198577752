import ImageWithFallback from '@/src/features/ui/image/image-with-fallback';
import { ResponseNativeCard } from '@/src/interfaces/data-contracts';
import { RelLink } from '../../ui/rel-link';

type Props = {
  item: ResponseNativeCard;
  isMobile: boolean;
};
export const NativeCard: React.FC<Props> = ({ item, isMobile }) => {
  const imageProps = {
    src: isMobile
      ? item.nativeCardPictures?.mobileImagePath || item.nativeCardPictures?.tabletImagePath
      : item.nativeCardPictures?.desktopImagePath,
    alt: item.nativeCardPictures?.altTitle + ' Kobi Kredisi',
    width: '0',
    height: '0',
    className: 'w-full h-auto shadow-faq',
    objectFit: 'contain',
  };

  return (
    <>
      {item.link && item.target ? (
        <RelLink href={item.link} target={item.target} className="w-full h-auto shadow-faq">
          <ImageWithFallback {...imageProps} />
        </RelLink>
      ) : (
        <ImageWithFallback {...imageProps} />
      )}
    </>
  );
};

export default NativeCard;
