'use client';
import { CampaignContainer } from '@/src/features/ui/campaign-container/campaign-container';
import ImageWithFallback from '@/src/features/ui/image/image-with-fallback';
import RoundedContainer from '@/src/features/ui/rounded-container';
import { CurrencyTypeMissing } from '@/src/interfaces/missing-types';
import { Pages } from '@/src/interfaces/pages-route';
import { DeviceContext } from '@/src/store/contexts/device-context';
import fmt from '@/src/utils/helpers/number-format';
import { isNullOrWhitespace } from '@/src/utils/helpers/validation';
import { useContext } from 'react';
import LinkLikeButton from '../../shared/link-like-button';
import Heading from '../../ui/heading';

type Props = {
  creditCardDetail: Pages.CreditcardDetailList.ResponseBody;
};

export const CardProduct: React.FC<Props> = ({ creditCardDetail }) => {
  const { isMobile } = useContext(DeviceContext);

  return (
    <RoundedContainer
      noShadow={isMobile}
      className="flex flex-col justify-between py-5 px-4 md:py-10 md:px-12 md:flex-row"
    >
      <div className="flex flex-col md:gap-5 gap-3 justify-center items-center w-full">
        <div className="w-[186px] h-[118px] md:w-[284px] md:h-[180px] relative">
          <ImageWithFallback
            src={creditCardDetail.productInfo?.imageForDetail}
            alt={creditCardDetail.seoInfo?.title}
            fill
            sizes="(max-width: 1120px) 50vw,
              33vw"
          />
        </div>
        <div>
          <Heading tagType="h1" cls="font-bold md:text-base text-sm text-black800">
            {creditCardDetail.productInfo?.bank?.name}
            <span className="ml-1 font-semibold md:text-base">{creditCardDetail.productInfo?.name}</span>
          </Heading>
          {/*todo bottom black color is not correct*/}
        </div>
        {isNullOrWhitespace(creditCardDetail.productInfo?.advantages) ? (
          ''
        ) : (
          <div
            className="table-list-formatting table-border"
            dangerouslySetInnerHTML={{
              __html: creditCardDetail.productInfo?.advantages ?? '',
            }}
          ></div>
        )}
      </div>
      <div className="flex flex-col gap-8 w-full justify-between md:mt-0 mt-[34px]">
        <div>
          <div className="flex flex-row justify-between py-3 border-b md:border-t-0 border-t border-black200 text-black600">
            <div className="font-semibold text-sm text-black600">
              {/* {creditCardDetail.productInfo?.annualFeeText} */}
              Yıllık Ücret
            </div>
            <div className="text-base font-bold text-black1000">
              {isNullOrWhitespace(creditCardDetail.productInfo?.annualFeeText) ? (
                'Ücretsiz'
              ) : (
                // <div
                //   dangerouslySetInnerHTML={{
                //     __html: creditCardDetail.productInfo?.annualFeeAsString ?? '',
                //   }}
                // />
                <div>{fmt(creditCardDetail.productInfo?.annualFee, CurrencyTypeMissing.TRY)}</div>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between py-3 border-b border-black200">
            <div className="font-semibold text-sm text-black600">Kazanç Türü</div>
            <div className="text-base font-bold text-black1000">{creditCardDetail.productInfo?.gainType}</div>
          </div>
        </div>
        <div>
          {creditCardDetail.productInfo?.heroCampaigns && creditCardDetail.productInfo.heroCampaigns.length > 0 && (
            <CampaignContainer heroCampaigns={creditCardDetail.productInfo?.heroCampaigns} />
          )}
        </div>
        {creditCardDetail.productInfo?.recourseLink && creditCardDetail.productInfo.isContract && (
          <LinkLikeButton
            href={creditCardDetail.productInfo.recourseLink}
            text={creditCardDetail?.productInfo?.buttonText ?? 'Hemen Başvur'}
            className="w-full h-[48px] md:text-base text-sm rounded-lg"
          />
        )}
      </div>
    </RoundedContainer>
  );
};
