import classNames from 'classnames';
import { RelLink } from '../../ui/rel-link';
type Props = {
  href: string;
  text: string;
  external?: boolean;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void | null;
};

export const LinkLikeButton: React.FC<Props> = (props) => {
  const href = props.href;
  const text = props.text;
  const external = props.external === undefined ? false : props.external;
  const className = props.className;
  const onClick = props.onClick;

  return (
    <RelLink
      onClick={onClick}
      href={href}
      className={classNames(
        'h-[48px] rounded-lg border border-purple500 font-bold text-sm md:text-base flex items-center justify-center',
        external ? 'bg-white text-purple500' : 'bg-purple500 text-white',
        { 'pointer-events-none': props.disabled },
        className
      )}
    >
      {text}
      {props.children}
    </RelLink>
  );
};
