'use client';

import { ResponseWidgetDetail } from '@/src/interfaces/data-contracts';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Heading from '../heading';
import { RelLink } from '../rel-link';
import { AccordionTabIcon } from './accordion-tab-icon';

type TabItemTypes = {
  sendKey: number;
  accordionTabItemClasses: string;
  accordionTitleClasses: string;
  accordionTextClasses: string;
  accordionVisibleTab: number | null;
  accordionTabIcon: boolean;
  accordionContentBtnVisible: boolean;
  accordionContentBtnText: string;
  accordionContentBtnClasses: string;
};

export const AccordionTabItem: React.FC<ResponseWidgetDetail & TabItemTypes> = ({
  sendKey,
  title,
  description,
  accordionTabItemClasses,
  accordionTitleClasses,
  accordionTextClasses,
  accordionVisibleTab,
  accordionTabIcon,
  accordionContentBtnVisible,
  accordionContentBtnText,
  accordionContentBtnClasses,
  link,
}) => {
  const [displayState, setDisplayState] = useState(true);
  useEffect(() => {
    if (accordionVisibleTab !== sendKey) {
      setDisplayState(false);
    }
  }, []);

  useEffect(() => {
    if (sendKey === accordionVisibleTab) {
      setDisplayState(true);
    }
  }, [accordionVisibleTab, sendKey]);

  const displayStateHandler = () => {
    setDisplayState((latestDisplayState) => !latestDisplayState);
  };

  return (
    <div className={accordionTabItemClasses}>
      <button
        className={classNames(
          'flex w-full flex-row items-center justify-between text-lg hover:cursor-pointer',
          {},
          accordionTitleClasses
        )}
        onClick={displayStateHandler}
      >
        <Heading tagType="h2" cls="text-lg font-semibold text-left ">
          {title}
        </Heading>
        {accordionTabIcon && <AccordionTabIcon displayState={displayState} />}
      </button>
      {displayState && (
        <div className={classNames('flex flex-col', {}, accordionTextClasses)}>
          <div dangerouslySetInnerHTML={{ __html: description ?? '' }} className="accordion_content text-[16px]" />
          {accordionContentBtnVisible && (
            <RelLink
              href={link ?? '/'}
              className={classNames('mt-8 flex justify-around', {}, accordionContentBtnClasses)}
            >
              {accordionContentBtnText}
            </RelLink>
          )}
        </div>
      )}
    </div>
  );
};
